class BackToTop {
  constructor(block) {
    this.button = block

    this.events()
  }

  events() {
    
    window.addEventListener("scroll", (e) => {
      let scrollableHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      this.button.classList.toggle('show', (window.scrollY / scrollableHeight) >= 0.4); // around half way down...
    })

    this.button.addEventListener("click", e => {
      window.scroll(0, 0)
      return false;
    })

  }

}

export default BackToTop
