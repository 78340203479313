class MobileMenu {
  constructor(block) {
    this.block = block
    this.closeButton = block.querySelector(".mobile-menu__close")
    this.navItems = block.querySelectorAll(".mobile-menu__nav-item")
    this.events()
  }

  events() {
    //Close button
    this.closeButton.addEventListener("click", () => this.closeMenu())

    //Keypress listener
    document.addEventListener("keyup", (e) => {
      this.keypressHandler(e)
    })

    this.navItems.forEach((item) => {
      const link = item.querySelector(".mobile-menu__nav-item-link")

      if (link) {
        link.addEventListener("click", (e) => this.openSubmenu(e, item))
      }
    })
  }

  openSubmenu(e, item) {
    const submenu = item.querySelector(".mobile-menu__level-2")
    if (submenu) {
      e.preventDefault()
      submenu.classList.add("mobile-menu__level-2--active")
    }
  }

  keypressHandler(e) {
    //Close menu if escape key pressed
    if (e.keyCode == 27) {
      this.closeMenu()
    }
  }

  closeMenu() {
    const activeSubmenu = this.block.querySelector(".mobile-menu__level-2--active")
    if (activeSubmenu) {
      activeSubmenu.classList.remove("mobile-menu__level-2--active")
    } else {
      this.block.classList.remove("mobile-menu--active")
    }
  }
}

export default MobileMenu
